import React from "react";
import Navbar from "./NavBar";


function Main(){
    return(
        <>

        </>
    )
}

export default Main;
