import React from "react";
import './style/navbar.css';

function Navbar(){

return(
    <nav>
        <div className="navbar-container">
            <img alt = "ecmi-logo" className="navbar-logo" src="/images/ecmi-logo.png"/>
        </div>
    </nav>
)
}

export default Navbar;