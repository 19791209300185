import React,{useState,useEffect}from "react";
import './style/ministry.css';
import ministryData from '../data/ministries.json'

function Ministry() {

 
  const ministries = Object.values(ministryData.ministries)
  const [clickMinistry,setClickMinistry]=useState({});
  const ministry = ministries.find(ministry => ministry.id === clickMinistry);
  const imageSrc1 = ministry ? '/images/mfair2024/' + ministry.img1 : null;
  const imageSrc2 = ministry ? '/images/mfair2024/' + ministry.img2 : null;



  const handleMinistryClick=(minitryID) =>{
    handleMinistryReset()
    setClickMinistry(minitryID);

  };

  const handleMinistryReset=()=>{
    setClickMinistry(null)
  }

  useEffect(() => {
    if (ministries.length > 0) {
      setClickMinistry(ministries[0].id); 
    }
  }, []);


  return (
    <div className="page wrapper">
      <div className='hero-section'></div>
      <section className="page-description">
          <p>
            This is a sample text,This is a sample text.This is a sample text,This is a sample text,This is a sample text,This is a sample text
            This is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample textThis is a sample text

          </p>
      </section>
      <section className="selection-wrapper">
          <div className="title-container">
          <h1>Join any of these ministries:</h1>
          </div>
           <div className="column-container">
              <div className="left-panel">
              
                {ministries.map(ministry=>(
                <div className="panel-item " key={ministry.id}>
                <label className={clickMinistry === ministry.id ? 'item-label click': 'item-label'} onClick={()=>{handleMinistryClick(ministry.id)}}>
                  {ministry.name}
                </label>
              </div>
              ))}

              </div>
              
              <div className="right-view">
          {/* Render content based on selected ministry */}
          {clickMinistry && ministries.find(ministry => ministry.id === clickMinistry) && (
            <>
              <h2>{ministries.find(ministry => ministry.id === clickMinistry).name}</h2>
              <p>
                {ministries.find(ministry => ministry.id === clickMinistry).description}
              </p>
              <div className="img-wrapper">
              {imageSrc1 && (
            <img className="ministry-image" alt={ministry.name} src={imageSrc1} />
              )}

              {imageSrc2 && (
            <img className="ministry-image" alt={ministry.name} src={imageSrc2} />
              )}
              
              </div>

            </>
          )}
        </div>

           </div>
           <hr/>
           <div className="register">
              <p>Ready to Serve? Scan or click the QR below</p>
              <a href="https://bit.ly/2024MinistryFair" target="_blank"><img src="/images/ministryfair-qr.png"/></a>
           </div>
      </section>
    
    </div>
  );
}

export default Ministry;
