import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import Ministry from './components/Ministry';
import Navbar from './components/NavBar';
import NotFound from './components/NotFound';


function App() {
  return (
<div>

  <Router>
  <Navbar/>
    <Routes>
      <Route path='/' exact element={<Ministry/>} />
      <Route path='/volunteer' exact element={<Ministry/>} />
      <Route  path='*'element={<NotFound/>} />
    </Routes>
  </Router>
</div>

  );
}

export default App;
